import { CanvasFile } from "@/models/wb/canvasFile.interface";
import * as wbFileService from "@/services/wb/wbFileService";

async function generatePreview(
  url: string,
  file: CanvasFile
  // cameraOrbit?: string
): Promise<string> {
  // creation of programmatic model-viewer to temporarily render 3d object
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const modelViewer = document.createElement("model-viewer") as any;
  if (modelViewer) {
    //setting basic properties to pass to the component as well as hiding it from user
    modelViewer.id = url;
    modelViewer.src = url;
    modelViewer.style = `width:200px; height:200px; position:fixed; z-index: 0;`;
    if (file.details?.sphericalCoordinates) {
      modelViewer.cameraOrbit = file.details.sphericalCoordinates;
    }
  }
  document.body.appendChild(modelViewer);
  //use of promise as to wait for the model viewer method to create a base64 url to pass to konva
  const urlPromise = new Promise(resolve => {
    modelViewer.addEventListener("poster-dismissed", function() {
      if (modelViewer.toDataURL) resolve(modelViewer.toDataURL("img/png", 0));
    });
  });
  // once the promise is consumed, we encapsulate the base64 url in a variable
  const glbUrl = (await urlPromise) as string;
  //we delete from the dom the temporary model viewer, data now in glbUrl
  modelViewer.parentNode.removeChild(modelViewer);
  //since we return a base64 url, like in the other functions, we don't need to create a different logic for konva
  return glbUrl;
}

export async function GetGlbElementDataUrl(file: CanvasFile): Promise<string> {
  const objectUrl = await wbFileService.fetchFile(file.fileId);
  return await generatePreview(objectUrl, file);
}
