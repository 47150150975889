






































































































import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import store from "@/store";
import { Page } from "@/models/wb/page.interface";
import { wbCanvasService } from "@/services/wb/wbCanvasService";
import * as wbConversionService from "@/services/wb/wbConversionService";
import { Stroke } from "@/models/wb/stroke.interface";
import { CanvasFile } from "@/models/wb/canvasFile.interface";
import iconcolorMixin from "@/mixins/iconcolorMixin";

export default Vue.extend({
  components: {
    VaButton
  },
  data: () => ({
    pageMenu: false
  }),
  computed: {
    currentPage(): string {
      return store.getters["canvas/shortMeeting"]?.selectedPage || "";
    },
    pages(): Page[] {
      return store.getters["canvas/pages"];
    },
    isWriter(): boolean {
      return store.getters["conference/isWriter"];
    },
    pageNumber(): number {
      let res = 0;
      for (const [i, p] of this.pages.entries()) {
        if (p.uuid === this.currentPage) res = i + 1;
      }
      return res;
    }
  },
  mixins: [iconcolorMixin],
  methods: {
    gotoPage(page: Page): void {
      this.$store.dispatch("canvas/changePage", {
        page: page,
        sendMessage: true
      });
    },
    changePage(next = true): void {
      for (const [i, p] of this.pages.entries()) {
        if (p.uuid == this.currentPage) {
          if (next) {
            if (i == this.pages.length - 1) {
              return this.onAddPage();
            } else {
              this.$store.dispatch("canvas/changePage", {
                page: this.pages[i + 1],
                sendMessage: this.isWriter
              });
            }
          } else {
            if (i == 0) {
              return;
            } else {
              this.$store.dispatch("canvas/changePage", {
                page: this.pages[i - 1],
                sendMessage: true
              });
            }
          }
        }
      }
    },
    showPageSelector(): void {
      this.pageMenu = true;
      this.$nextTick(() => {
        this.pages.map(page => {
          const stage = wbCanvasService.CreateStage(
            `preview${page.uuid}`,
            160,
            90,
            page.size?.width || 1,
            page.size?.height || 1,
            false,
            false
          );
          const strokeLayer = wbCanvasService.CreateLayer();
          const imageLayer = wbCanvasService.CreateLayer();
          page?.files.map(async (file: CanvasFile) => {
            imageLayer?.add(
              await wbConversionService.File2KonvaNode(file, false)
            );
          });
          page?.strokes.map((stroke: Stroke) => {
            strokeLayer?.add(wbConversionService.Stroke2Line(stroke));
          });
          stage.add(imageLayer);
          stage.add(strokeLayer);
        });
      });
    },
    onRemovePage(pageUuid: string): void {
      if (this.isWriter) {
        this.$store.dispatch("canvas/deletePage", {
          pageUuid: pageUuid,
          isLocalAction: true
        });
      }
    },
    onAddPage(): void {
      if (this.isWriter) {
        this.$store.dispatch("canvas/addLocalPage", {
          sendMessage: true
        });
      }
    },
    onClonePage(pageUuid: string): void {
      if (this.isWriter) {
        this.$store.dispatch("canvas/cloneLocalPage", {
          originalId: pageUuid
        });
      }
    }
  }
});
