





























































import Vue from "vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import MetaHelper from "@/services/metaHelper";
import MfaLogin from "@/components/organisms/auth/MfaLogin.vue";
import validationMixin from "@/mixins/validationMixin";
import VaLogMessage from "@/components/molecules/VaLogMessage.vue";
import CenterContent from "@/components/templates/CenterContent.vue";
import FederateLogin from "@/components/organisms/FederateLogin.vue";
import roomBackgroundMixin from "@/mixins/roomBackgroundMixin";

export default Vue.extend({
  name: "SignUp",
  components: {
    FederateLogin,
    VaLogMessage,
    MfaLogin,
    VaButton,
    CenterContent,
    VaTextField
  },
  mixins: [validationMixin, roomBackgroundMixin],
  meta() {
    return MetaHelper.buildMeta("signUp");
  },
  data: () => ({
    email: "",
    firstName: "",
    lastName: "",
    valid: true
  }),
  computed: {
    success(): boolean {
      return this.$store.getters["session/signupSuccess"];
    },
    mfaEnabled() {
      return (
        !process.env.VUE_APP_OAUTH_DISABLED ||
        process.env.VUE_APP_OAUTH_DISABLED === "false"
      );
    },
    token(): string {
      return this.$store.getters["session/mfaToken"];
    }
  },
  mounted() {
    this.$store.commit("session/setSignupSuccess", false);
  },
  methods: {
    onJoin() {
      if (
        (this.$refs.signUpForm as Vue & { validate: () => boolean }).validate()
      ) {
        this.$store.dispatch("session/signUp", {
          email: this.email,
          metadata: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            username: this.email
          }
        });
      }
    }
  }
});
