import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import "@/scss/common.scss";
import "@/plugins/vueLodash";
import "@/plugins/sentry";
import "@/plugins/vueGTM";
import "@/plugins/vueMeta";
import "@/plugins/vueKonva";
import "@/plugins/vmask";
import axiosInterceptor from "@/plugins/axiosInterceptor";
import i18n from "@/plugins/lang";
import "@/plugins/modelViewer";

Vue.config.productionTip = false;

axiosInterceptor();

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
