var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.token
        ? _c(
            "div",
            [
              _c("h2", [_vm._v(_vm._s(_vm.$t("signin.title")))]),
              _c("div", { staticClass: "mt-2 mb-8" }, [
                _vm._v(_vm._s(_vm.$t("signin.description")))
              ]),
              _vm.mfaEnabled
                ? _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("federate-login"),
                      _c("v-divider", { staticClass: "my-4" })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-form",
                {
                  ref: "signInForm",
                  attrs: { "lazy-validation": "", onSubmit: "return false;" },
                  on: { submit: _vm.onJoin },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("va-text-field", {
                    staticClass: "mb-8",
                    attrs: {
                      name: "email",
                      rules: [_vm.required, _vm.validMail],
                      label: _vm.$t("signin.email"),
                      dense: ""
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _c("va-text-field", {
                    staticClass: "mb-1",
                    attrs: {
                      rules: [_vm.required],
                      name: "password",
                      label: _vm.$t("signin.password"),
                      dense: "",
                      password: ""
                    },
                    model: {
                      value: _vm.pass,
                      callback: function($$v) {
                        _vm.pass = $$v
                      },
                      expression: "pass"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c("router-link", { attrs: { to: "/signup" } }, [
                        _vm._v(" " + _vm._s(_vm.$t("signUp.signUp")) + " ")
                      ]),
                      _vm._v(" - "),
                      _c("router-link", { attrs: { to: "/forgot" } }, [
                        _vm._v(" " + _vm._s(_vm.$t("signin.forgot")) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("va-button", {
                        staticClass: "flex-grow-1 mt-6",
                        attrs: {
                          disabled: !_vm.valid,
                          submit: "",
                          loading: _vm.$store.getters["session/isLoading"],
                          cta: _vm.$t("signin.cta")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("user-non-confirmed-error", {
                staticClass: "mt-4",
                attrs: { email: _vm.email }
              })
            ],
            1
          )
        : _vm._e(),
      _c("mfa-login")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }