var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "center-content",
    { attrs: { "light-bg": _vm.bgLight, "dark-bg": _vm.bgDark } },
    [
      _c(
        "div",
        { staticClass: "px-6 py-8 text-center" },
        [
          _c("h2", [_vm._v(_vm._s(_vm.$t("join.title")))]),
          _c("div", { staticClass: "mt-2 mb-8" }, [
            _vm._v(_vm._s(_vm.$t("join.description")))
          ]),
          _c(
            "v-form",
            {
              ref: "joinForm",
              attrs: { "lazy-validation": "", onSubmit: "return false;" },
              on: { submit: _vm.onJoin },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("va-text-field", {
                staticClass: "mb-8",
                attrs: {
                  rules: [_vm.required, _vm.nineDigits],
                  label: _vm.$t("join.meetingidLabel"),
                  name: "meeting-id",
                  mask: "###-###-###"
                },
                model: {
                  value: _vm.roomId,
                  callback: function($$v) {
                    _vm.roomId = $$v
                  },
                  expression: "roomId"
                }
              }),
              _c("va-text-field", {
                staticClass: "mb-8",
                attrs: {
                  rules: [_vm.required],
                  label: _vm.$t("join.pinLabel"),
                  name: "pin",
                  mask: "####"
                },
                model: {
                  value: _vm._pin,
                  callback: function($$v) {
                    _vm._pin = $$v
                  },
                  expression: "_pin"
                }
              }),
              _c("va-text-field", {
                staticClass: "mb-1",
                attrs: {
                  rules: [_vm.required],
                  label: _vm.$t("join.nickLabel"),
                  name: "nickname",
                  readonly: !!_vm.user
                },
                model: {
                  value: _vm._nick,
                  callback: function($$v) {
                    _vm._nick = $$v
                  },
                  expression: "_nick"
                }
              }),
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _vm.$store.getters["session/isAuthenticated"]
                    ? _c("router-link", { attrs: { to: "/" } }, [
                        _vm._v(" " + _vm._s(_vm.$t("join.gotoHome")) + " ")
                      ])
                    : _c("router-link", { attrs: { to: "/presignin" } }, [
                        _vm._v(" " + _vm._s(_vm.$t("join.gotoSignIn")) + " ")
                      ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("va-button", {
                    staticClass: "flex-grow-1 mt-6",
                    attrs: {
                      disabled: !_vm.valid,
                      submit: "",
                      loading: _vm.$store.getters["session/isLoading"],
                      cta: _vm.$t("join.cta")
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }