




























































import Vue from "vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import { User } from "@/models/user.interface";
import VaButton from "@/components/atoms/VaButton.vue";
import store from "@/store";
import { ConferenceAuthData } from "@/models/conferenceAuthData.interface";
import MetaHelper from "@/services/metaHelper";
import validationMixin from "@/mixins/validationMixin";
import CenterContent from "@/components/templates/CenterContent.vue";
import roomBackgroundMixin from "@/mixins/roomBackgroundMixin";

export default Vue.extend({
  name: "GuestJoin",
  components: {
    CenterContent,
    VaTextField,
    VaButton
  },
  mixins: [validationMixin, roomBackgroundMixin],
  meta() {
    return MetaHelper.buildMeta("guestJoin");
  },
  data: () => ({
    roomId: "",
    pin: "",
    nick: "",
    valid: true
  }),
  computed: {
    authData(): ConferenceAuthData {
      return this.$store.getters["conference/authData"];
    },
    user(): User {
      return this.$store.getters["users/user"];
    },
    _nick: {
      get: function(): string {
        const user = this.$store.getters["users/user"];
        return user ? user.userNickname : "";
      },
      set: function(newValue: string) {
        this.nick = newValue;
      }
    },
    _pin: {
      get: function(): string {
        return this.$route.query?.pin ? this.$route.query.pin.toString() : "";
      },
      set: function(newValue: string) {
        this.pin = newValue;
      }
    }
  },
  watch: {
    authData: function(newVal) {
      if (newVal.token) {
        this.$router.push(`/join/session`);
      }
    }
  },
  mounted() {
    if (this.$route?.query?.meetingId) {
      this.roomId = this.$route.query.meetingId as string;
    }
  },
  methods: {
    async onJoin() {
      if (
        (this.$refs.joinForm as Vue & { validate: () => boolean }).validate()
      ) {
        await store.dispatch("conference/init", {
          meetingId: this.roomId,
          pin: this._pin ? this._pin : this.pin,
          nickname: this._nick ? this._nick : this.nick
        });
      }
    },
    onLogout() {
      this.$store.dispatch("session/logout");
    }
  }
});
