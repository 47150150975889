var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({ staticClass: "d-flex pa-1" }, on),
                    [
                      _c("va-loader", {
                        attrs: { size: "s", "is-loading": _vm.isLoading }
                      }),
                      !_vm.isLoading
                        ? _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "", "nudge-bottom": 14 },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      return [
                                        _c("va-button", {
                                          attrs: {
                                            size: "s",
                                            color: _vm.iconColor,
                                            type: "squareText",
                                            icon: _vm.showMenu
                                              ? "va va-direction-up"
                                              : "va va-direction-down"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.showMenu = !_vm.showMenu
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.showMenu,
                                callback: function($$v) {
                                  _vm.showMenu = $$v
                                },
                                expression: "showMenu"
                              }
                            },
                            [
                              _c(
                                "v-list",
                                { staticClass: "secondary-outline rounded" },
                                _vm._l(_vm.connectedUsers, function(
                                  fulluser,
                                  index
                                ) {
                                  return _c(
                                    "v-list-item",
                                    { key: index, staticClass: "d-flex" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-grow-1 mr-1" },
                                        [_vm._v(_vm._s(fulluser.userNickname))]
                                      ),
                                      _vm.authData.user.isModerator &&
                                      !fulluser.isModerator
                                        ? _c("va-button", {
                                            attrs: {
                                              cta: _vm.isWriter(fulluser)
                                                ? _vm.$t("shared.write")
                                                : _vm.$t("shared.read"),
                                              color: _vm.isWriter(fulluser)
                                                ? "success"
                                                : "secondary",
                                              disabled:
                                                _vm.totalWriters >= 4 &&
                                                !_vm.isWriter(fulluser),
                                              size: "xs"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.toggleWriteRole(
                                                  fulluser
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.connectedUsers.slice(0, 3), function(user, n) {
                        return _c("va-avatar", {
                          key: n,
                          staticClass: "mx-1",
                          attrs: {
                            image: user.avatar,
                            text: user.userNickname,
                            size: "s"
                          }
                        })
                      })
                    ],
                    2
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("rooms.members")))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }