var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column toolbar-left secondary-outline rounded pa-3 elevation-8"
    },
    [
      _c("tool-selector", {
        attrs: {
          id: "drag-tool",
          icon: "va va-cursor",
          active: _vm.tool === "drag",
          "color-selector": false
        },
        on: {
          selectTool: function($event) {
            return _vm.$store.dispatch("wb/tools/setTool", "drag")
          }
        }
      }),
      _vm.isWriter
        ? _c("tool-selector", {
            staticClass: "mt-3",
            attrs: {
              id: "draw-tool",
              name: "draw-tool",
              icon: "va va-pencil",
              color: _vm.drawColor,
              active: _vm.tool === "draw",
              size: _vm.drawSize
            },
            on: {
              selectTool: function($event) {
                return _vm.$store.dispatch("wb/tools/setTool", "draw")
              },
              selectColor: function(color) {
                return _vm.$store.commit("wb/tools/setDrawColor", color)
              },
              changeSize: function(size) {
                return _vm.$store.commit("wb/tools/setDrawSize", size)
              }
            }
          })
        : _vm._e(),
      _vm.isWriter
        ? _c("tool-selector", {
            staticClass: "mt-3",
            attrs: {
              id: "marker-tool",
              name: "marker-tool",
              icon: "va va-marker",
              color: _vm.markerColor,
              active: _vm.tool === "marker",
              size: _vm.markerSize
            },
            on: {
              selectTool: function($event) {
                return _vm.$store.dispatch("wb/tools/setTool", "marker")
              },
              selectColor: function(color) {
                return _vm.$store.commit("wb/tools/setMarkerColor", color)
              },
              changeSize: function(size) {
                return _vm.$store.commit("wb/tools/setMarkerSize", size)
              }
            }
          })
        : _vm._e(),
      _vm.isWriter
        ? _c("tool-selector", {
            staticClass: "mt-3",
            attrs: {
              id: "eraser-tool",
              name: "eraser-tool",
              icon: "va va-eraser",
              active: _vm.tool === "eraser",
              size: _vm.eraserSize,
              "color-selector": false
            },
            on: {
              selectTool: function($event) {
                return _vm.$store.dispatch("wb/tools/setTool", "eraser")
              },
              changeSize: function(size) {
                return _vm.$store.commit("wb/tools/setEraserSize", size)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }