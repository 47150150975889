import Konva from "konva";
import store from "@/store";
import * as conferenceService from "@/services/conferenceSocketService";
import { KonvaEventObject } from "konva/lib/Node";
import * as wbConversionService from "@/services/wb/wbConversionService";
import { CanvasFile } from "@/models/wb/canvasFile.interface";
import { getCurrentPage } from "@/models/wb/shortMeeting.interface";

export function startDrawLine(
  e: KonvaEventObject<TouchEvent | MouseEvent | PointerEvent>
): void {
  if (
    (store.getters["wb/tools/tool"] !== "drag" ||
      !(e.target instanceof Konva.Image || e.target instanceof Konva.Rect)) &&
    !store.getters["wb/files/focusOnfile"]
  ) {
    store.commit("wb/files/deselectImage", { root: true });
  }

  if (store.getters["wb/tools/tool"] === "drag") return;
  const position = e.currentTarget.getRelativePointerPosition();

  let color, size, opacity;

  switch (store.getters["wb/tools/tool"]) {
    case "draw":
      color = store.getters["wb/tools/drawColor"];
      size = Number(store.getters["wb/tools/drawSize"]);
      opacity = 1;
      break;
    case "marker":
      color = store.getters["wb/tools/markerColor"];
      size = Number(store.getters["wb/tools/markerSize"]);
      opacity = 0.5;
      break;
    case "eraser":
      color = "white";
      size = Number(store.getters["wb/tools/eraserSize"]);
      opacity = 0.7;
      break;
    default:
      color = "black";
      size = 2;
  }
  const line = new Konva.Line({
    stroke: color,
    strokeWidth: size,
    points: [position.x, position.y],
    bezier: false,
    lineCap: "round",
    lineJoin: "round",
    tension: 0.5,
    draggable: false,
    opacity: opacity,
    hitStrokeWidth: 0
  });

  const stroke = {
    color: color,
    width: size,
    height: size,
    flattenedPoints: [position.x, position.y],
    points: [{ x: position.x, y: position.y, pressure: 0.5 }],
    mode: store.getters["wb/tools/tool"]
  };

  store.dispatch("canvas/addNewLine", { line: line, stroke: stroke });
  store.dispatch("canvas/addPointToLine", {
    x: position.x,
    y: position.y,
    pressure: 0.5
  });
}

export function drawLine(
  e: KonvaEventObject<TouchEvent | MouseEvent | PointerEvent>
): void {
  if (
    store.getters["wb/tools/tool"] === "drag" ||
    store.getters["canvas/currentLine"] == undefined
  )
    return;

  // prevent scrolling on touch devices
  e.evt.preventDefault();
  const position = e.currentTarget.getRelativePointerPosition();
  if (position.x < 0 || position.y < 0) return;

  store.dispatch("canvas/addPointToLine", {
    x: position.x,
    y: position.y,
    pressure: 0.5
  });
}

export function endDrawLine(): void {
  if (store.getters["wb/tools/tool"] === "drag") return;
  let size = store.getters["canvas/page"].size;
  let fileId = "";
  if (
    store.getters["wb/files/focusOnfile"] &&
    store.getters["wb/files/selectedImage"]
  ) {
    size = store.getters["wb/files/selectedImage"].file.size;
    fileId = store.getters["wb/files/selectedImage"].file.uuid;
  }
  const socketStroke = wbConversionService.strokeSerializer(
    store.getters["canvas/currentLine"]?.stroke,
    store.getters["wb/tools/tool"],
    size,
    fileId
  );
  //Send signalR message
  conferenceService.sendStroke(store.getters["conference/socket"], {
    pageId: store.getters["canvas/page"].uuid,
    stroke: socketStroke
  });

  store.dispatch("canvas/endDrawLine");
  //Use case eraser: get points from server
  if (store.getters["wb/tools/tool"] === "eraser") {
    fetchCurrentStrokes();
  }
  if (store.getters["wb/tools/tool"] === "draw") {
    const page = store.getters["canvas/page"];
    if (page.strokes) {
      store.commit("canvas/updateLayerStrokes", {
        strokes: page.strokes
      });
    }
  }
}

//getShortMeeting api call - eraser HACK - chiedere backend creare api per ricevere solo i strokes della pagina corrente
export async function fetchCurrentStrokes(): Promise<boolean> {
  const meetingData = await conferenceService.getShortMeeting();
  const selectedPage = getCurrentPage(meetingData);

  if (store.getters["wb/files/focusOnfile"]) {
    const newFile = selectedPage?.files.find(image => {
      return image.uuid == store.getters["wb/files/selectedImage"].file.uuid;
    });
    store.commit("wb/files/updateFocusImage", newFile);
  } else {
    const strokes = selectedPage ? selectedPage.strokes : [];
    store.commit("canvas/updateLayerStrokes", {
      strokes: strokes
    });
  }
  return true;
}

export async function getUpdatedFile(
  file: CanvasFile
): Promise<CanvasFile | undefined> {
  const meetingData = await conferenceService.getShortMeeting();
  const selectedPage = getCurrentPage(meetingData);
  return selectedPage?.files.find(image => {
    return image?.uuid == file?.uuid;
  });
}
