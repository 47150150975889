































































import Vue from "vue";
import VaTextField from "@/components/atoms/VaTextField.vue";
import VaButton from "@/components/atoms/VaButton.vue";
import MetaHelper from "@/services/metaHelper";
import MfaLogin from "@/components/organisms/auth/MfaLogin.vue";
import validationMixin from "@/mixins/validationMixin";
import UserNonConfirmedError from "@/components/organisms/UserNonConfirmedError.vue";
import FederateLogin from "@/components/organisms/FederateLogin.vue";

export default Vue.extend({
  name: "SignInForm",
  components: {
    FederateLogin,
    UserNonConfirmedError,
    MfaLogin,
    VaButton,
    VaTextField
  },
  mixins: [validationMixin],
  meta() {
    return MetaHelper.buildMeta("signIn");
  },
  data: () => ({
    email: "",
    pass: "",
    valid: true
  }),
  computed: {
    mfaEnabled() {
      return (
        !process.env.VUE_APP_OAUTH_DISABLED ||
        process.env.VUE_APP_OAUTH_DISABLED === "false"
      );
    },
    token(): string {
      return this.$store.getters["session/mfaToken"];
    }
  },
  methods: {
    onJoin() {
      if (
        (this.$refs.signInForm as Vue & { validate: () => boolean }).validate()
      ) {
        this.$store.dispatch("session/login", {
          username: this.email,
          password: this.pass
        });
      }
    }
  }
});
