






















































import Vue from "vue";
import ToolSelector from "@/components/molecules/ToolSelector.vue";
import store from "@/store";
// import ZoomController from "@/components/organisms/ZoomController.vue";

export default Vue.extend({
  components: {
    // ZoomController,
    ToolSelector
  },
  data: () => ({
    pageMenu: false,
    sizeMenu: false,
    drawMenu: false,
    markerMenu: false,
    eraserMenu: false
  }),
  computed: {
    tool(): string {
      return store.getters["wb/tools/tool"];
    },
    drawColor(): string {
      return store.getters["wb/tools/drawColor"];
    },
    markerColor(): string {
      return store.getters["wb/tools/markerColor"];
    },
    drawSize(): number {
      return store.getters["wb/tools/drawSize"];
    },
    eraserSize(): number {
      return store.getters["wb/tools/eraserSize"];
    },
    markerSize(): number {
      return store.getters["wb/tools/markerSize"];
    },
    isWriter(): boolean {
      return store.getters["conference/isWriter"];
    }
  },
  methods: {}
});
