

























import Vue from "vue";
import VaButton from "@/components/atoms/VaButton.vue";
import { HTMLInputEvent } from "@/models/htmlInputEvent.interface";
import * as wbFileService from "@/services/wb/wbFileService";
import iconcolorMixin from "@/mixins/iconcolorMixin";

export default Vue.extend({
  components: {
    VaButton
  },
  data: () => ({}),
  mixins: [iconcolorMixin],
  computed: {},
  methods: {
    onUploadFile() {
      const element = this.$refs.uploader as HTMLElement;
      element.click();
    },
    async handleUpload(event: HTMLInputEvent) {
      if (event?.target?.files?.length) {
        // this.$store.commit("wb/files/setAssetsLoading", true);
        const file = event.target.files[0];
        const fileBlob = URL.createObjectURL(file);
        await wbFileService.uploadFile(fileBlob, file.name);
        if (this.$refs.uploader) {
          (this.$refs.uploader as HTMLInputElement).value = "";
        }
      }
    }
  }
});
