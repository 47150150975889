var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "model-container" } },
    [
      _c("model-viewer", {
        ref: "modelViewer",
        staticClass: "fullScreenOverlay",
        attrs: {
          alt: "3dModel",
          id: "modelViewer",
          src: _vm.resource,
          "interaction-prompt": "none",
          ar: "",
          "ar-modes": "webxr scene-viewer quick-look",
          "shadow-intensity": "1",
          "camera-controls": "",
          "touch-action": "pan-x pan-y",
          "camera-orbit": "auto"
        },
        on: {
          mousedown: _vm.onStartRotateModel,
          mousemove: _vm.onRotateModel,
          mouseup: _vm.onStopRotateModel,
          touchstart: _vm.onStartRotateModel,
          touchmove: _vm.onRotateModel,
          touchend: _vm.onStopRotateModel,
          "poster-dismissed": _vm.setOrbit,
          wheel: _vm.onZoomModel
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }