var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "center-content",
    { attrs: { "light-bg": _vm.bgLight, "dark-bg": _vm.bgDark } },
    [
      _c("div", { staticClass: "pa-6" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-space-between" },
          [
            _c("div", { staticClass: "text-left" }),
            _c("router-link", { attrs: { to: "/signup" } }, [
              _vm._v(" " + _vm._s(_vm.$t("signUp.signUp")) + " ")
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-center  mt-n12 mb-8" },
          [
            _c("va-avatar", {
              staticClass: "mt-n10 elevation-3",
              attrs: { image: _vm.logo, size: "l" }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mb-12 text-center" },
          [_c("sign-in-form")],
          1
        ),
        _vm.guestEnabled
          ? _c(
              "div",
              {
                staticClass:
                  "border-ra border-a background-dark d-flex cursor-pointer pa-4",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/join")
                  }
                }
              },
              [
                _c("div", { staticClass: "flex-grow-1" }, [
                  _c("h3", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$t("join.title")))
                  ])
                ]),
                _c("va-icon", { attrs: { icon: "va va-direction-right" } })
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "fullscreen" },
          model: {
            value: _vm.loginDialog,
            callback: function($$v) {
              _vm.loginDialog = $$v
            },
            expression: "loginDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "text-center pa-6" },
            [_c("sign-in-form")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }