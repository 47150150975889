import { MutationTree, ActionTree, ActionContext, GetterTree } from "vuex";
import store, { RootState } from "./index";
import logger from "@/services/loggerService";
import * as conferenceService from "@/services/conferenceSocketService";
import { Socket } from "@/models/socket.interface";
import { User } from "@/models/user.interface";
import { ConferenceAuthData } from "@/models/conferenceAuthData.interface";
import Vue from "vue";
import { GuestRoom } from "@/models/room.interface";
import i18n from "@/plugins/lang";

export interface ConferenceState {
  isLoading: boolean;
  socket: Socket | undefined;
  authData: ConferenceAuthData | undefined;
  users: User[];
  guestRoom?: GuestRoom;
  initInvoked: boolean;
  initialized: boolean;
  connectionReady: boolean;
  isWriter: boolean;
  isDisconnected: boolean;
}

type SessionContext = ActionContext<ConferenceState, RootState>;

export const namespaced = true;

export const state = (): ConferenceState => ({
  isLoading: false,
  socket: undefined,
  authData: Vue.$cookies.get("conference-auth-data") || undefined,
  users: [],
  guestRoom: undefined,
  initInvoked: false,
  initialized: false,
  connectionReady: false,
  isWriter: false,
  isDisconnected: false
});

export const getters: GetterTree<ConferenceState, RootState> = {
  isLoading: (state): boolean => {
    return state.isLoading;
  },
  users: (state): User[] => {
    return state.users;
  },
  authData: (state): ConferenceAuthData | undefined => {
    return state.authData;
  },
  guestRoom: (state): GuestRoom | undefined => {
    return state.guestRoom;
  },
  initInvoked: (state): boolean => {
    return state.initInvoked;
  },
  initialized: (state): boolean => {
    return state.initialized;
  },
  socket: (state): Socket | undefined => {
    return state.socket;
  },
  connectionReady: (state): boolean => {
    return state.connectionReady;
  },
  isWriter: (state): boolean => {
    return state.isWriter;
  },
  isDisconnected: (state): boolean => {
    return state.isDisconnected;
  }
};

export const mutations: MutationTree<ConferenceState> = {
  setLoading(state: ConferenceState, loading: boolean) {
    state.isLoading = loading;
  },
  setSocket(state: ConferenceState, socket: Socket) {
    state.socket = socket;
  },
  setAuthData(state: ConferenceState, authData: ConferenceAuthData) {
    state.authData = authData;
  },
  setUsers(state: ConferenceState, users: User[]) {
    state.users = users;
  },
  addUser(state: ConferenceState, data: User) {
    if (
      state.users?.every((user: User) => {
        return user.userId != data.userId;
      })
    ) {
      state.users.push(data);
    }
  },
  removeUser(state: ConferenceState, userId: string) {
    state.users = state.users.filter((user: User) => {
      return user.userId !== userId;
    });
  },
  updateUser(state: ConferenceState, data: User) {
    state.users = state.users.map((user: User) => {
      if (user.userId == data.userId) {
        return data;
      } else {
        return user;
      }
    });
  },
  setGuestRoom(state: ConferenceState, data: GuestRoom | undefined) {
    state.guestRoom = data;
  },
  activateGuestRoom(state: ConferenceState) {
    if (state.guestRoom) {
      state.guestRoom.webConferenceActive = true;
    } else {
      state.guestRoom = {
        meetingId: "",
        webConferenceActive: true
      };
    }
  },
  initInvoked(state: ConferenceState, loading: boolean) {
    state.initInvoked = loading;
  },
  setConnectionReady(state: ConferenceState, ready: boolean) {
    state.connectionReady = ready;
  },
  isDisconnected(state: ConferenceState, value: boolean) {
    state.isDisconnected = value;
  },
  setWriter(state: ConferenceState, canWrite: boolean) {
    state.isWriter = canWrite;
  }
  // userRoleRemoved(
  //   state: ConferenceState,
  //   data: { userIdList: string; roleName: string }
  // ) {
  //   if (
  //     state.authData?.user.userId == data.userIdList &&
  //     data.roleName == "Writer"
  //   ) {
  //     state.isWriter = false;
  //   }
  //   state.users = state.users.map(user => {
  //     if (user.userId == data.userIdList && user.roles?.length) {
  //       const index = user.roles.indexOf(data.roleName);
  //       if (index > -1) {
  //         user.roles.splice(index, 1);
  //       }
  //     }
  //     return user;
  //   });
  // },
};

export const actions: ActionTree<ConferenceState, RootState> = {
  async init(
    context: SessionContext,
    data: { meetingId: string; pin: string; nickname: string }
  ) {
    context.commit("setLoading", true);
    context.commit("initInvoked", true);
    const room = context.rootState.room.activeRoom;
    // const me = context.rootState.session.user;

    if (context.state.socket) {
      await conferenceService.stopConferenceConnection(context.state.socket);
    }
    try {
      const {
        socket,
        authdata
      } = await conferenceService.initConferenceConnection(
        data.meetingId,
        data.pin,
        data.nickname,
        context.state.authData?.user.userId
      );
      Vue.$cookies.set("conference-auth-data", authdata, authdata.expires);
      context.commit("setSocket", socket);
      context.commit("setAuthData", authdata);

      const canwrite =
        !!authdata?.user?.roles?.length &&
        (authdata.user.roles.indexOf("Writer") >= 0 ||
          authdata.user.roles.indexOf("Moderator") >= 0);
      store.commit("conference/setWriter", canwrite);

      if (!room) {
        const guestRoom = await conferenceService.getMeeting();
        context.commit("conference/setGuestRoom", guestRoom, { root: true });
      }
      const users = await conferenceService.getUsers();
      // users.unshift(authdata.user);
      context.commit("setUsers", users);
      context.commit("addUser", authdata.user);

      const shortMeeting = await conferenceService.getShortMeeting();
      // context.commit("setShortMeeting", shortMeeting);
      context.commit("canvas/setShortMeeting", shortMeeting, { root: true });

      context.state.initialized = true;
      return authdata;
    } catch (err) {
      context.commit(
        "notifications/displayNotification",
        {
          message: i18n.t("signin.conferenceError"),
          type: "error"
        },
        { root: true }
      );
      logger.error(err);
      context.commit("initInvoked", false);
    } finally {
      context.commit("setLoading", false);
    }
  },

  async stop(context: SessionContext) {
    context.commit("initInvoked", false);
    if (context.state.socket) {
      Vue.$cookies.remove("conference-auth-data");
      await conferenceService.stopConferenceConnection(context.state.socket);
    }
    context.dispatch("videoConference/stop", undefined, { root: true });
    context.commit("setSocket", undefined);
    // context.commit("setShortMeeting", undefined);
    context.commit("canvas/setShortMeeting", undefined, { root: true });
  },

  userRoleAdded(
    context: SessionContext,
    data: { userIdList: string; roleName: string }
  ) {
    if (
      context.state.authData?.user.userId == data.userIdList &&
      data.roleName == "Writer"
    ) {
      context.commit("wb/files/setDraggableImages", true, { root: true });
      context.state.isWriter = true;
    }
    context.state.users = context.state.users.map(user => {
      if (user.userId == data.userIdList) {
        user.roles?.push(data.roleName);
      }
      return user;
    });
  },

  userRoleRemoved(
    context: SessionContext,
    data: { userIdList: string; roleName: string }
  ) {
    if (
      context.state.authData?.user.userId == data.userIdList &&
      data.roleName == "Writer"
    ) {
      context.commit("wb/files/setDraggableImages", false, { root: true });
      context.state.isWriter = false;
    }
    context.state.users = context.state.users.map(user => {
      if (user.userId == data.userIdList && user.roles?.length) {
        const index = user.roles.indexOf(data.roleName);
        if (index > -1) {
          user.roles.splice(index, 1);
        }
      }
      return user;
    });
  },

  async addUserRole(
    context: SessionContext,
    data: { user: User; role: string }
  ) {
    const res = await context.state.socket?.proxy.invoke(
      "AddRoleToUsers",
      [data.user.userId],
      data.role
    );
    if (res) {
      const roles = data.user.roles ? data.user.roles : [];
      roles.push(data.role);
      data.user.roles = roles;
      context.commit("updateUser", data.user);
    }
  },

  async removeUserRole(
    context: SessionContext,
    data: { user: User; role: string }
  ) {
    const res = await context.state.socket?.proxy.invoke(
      "RemoveRoleFromUsers",
      [data.user.userId],
      data.role
    );
    if (res) {
      const roles = data.user.roles ? data.user.roles : [];
      data.user.roles = roles.filter(role => {
        return role !== data.role;
      });
      context.commit("updateUser", data.user);
    }
  },

  async setWebConferenceStatus(context: SessionContext, status: string) {
    const socket = context.state.socket;
    if (socket) {
      try {
        await conferenceService.setWebConferenceStatus(socket, status);
      } catch (err) {
        logger.error(err);
      }
    }
  }
};
