var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: { id: "whiteboard-container" },
      on: {
        dragover: function($event) {
          $event.preventDefault()
        },
        drop: function($event) {
          $event.preventDefault()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "loader-wrapper" },
        [
          _c("va-loader", {
            attrs: { isLoading: !_vm.shortMeeting, size: "l", color: "primary" }
          })
        ],
        1
      ),
      _vm.shortMeeting
        ? _c(
            "div",
            {
              ref: "whiteboard-wrapper",
              class:
                "whiteboard-wrapper " + (_vm.moveActive ? "panCursor" : ""),
              on: {
                pointerover: function($event) {
                  return _vm.setDrawToolIfPen($event)
                },
                dragover: function($event) {
                  $event.preventDefault()
                },
                drop: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _vm.$store.getters["wb/files/isFullscreenResourceLoading"]
                ? _c(
                    "div",
                    {
                      staticClass:
                        "fullScreenOverlay d-flex justify-center align-center"
                    },
                    [
                      _c("va-loader", {
                        attrs: {
                          isLoading:
                            _vm.$store.getters[
                              "wb/files/isFullscreenResourceLoading"
                            ],
                          size: "l",
                          color: "primary"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$store.getters["wb/files/focusOnfile"]
                ? _c("div", { staticClass: "focus-image-overly" }, [
                    _c(
                      "div",
                      {
                        staticClass: "focus-image-wrapper",
                        attrs: { id: "focusImageWrapper" }
                      },
                      [
                        _c("div", { attrs: { id: "focusImageContainer" } }),
                        _vm.focusTextBox ? _c("text-box-detail") : _vm._e(),
                        _vm.focusURLBox
                          ? _c("va-frame", {
                              attrs: {
                                url: _vm.selectedImage.file.details.text
                              }
                            })
                          : _vm._e(),
                        _vm.focusVideo
                          ? _c("video", {
                              attrs: {
                                id: "videoPlayer",
                                src: _vm.resource,
                                controls: ""
                              }
                            })
                          : _vm._e(),
                        _vm.focusGLB
                          ? _c("solid-viewer", {
                              ref: "threeDViewer",
                              attrs: { resource: _vm.resource },
                              on: {
                                snapshotAcquired:
                                  _vm.createNewImageFromModelViewer
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("div", {
                attrs: { id: "wbContainer" },
                on: {
                  drop: function($event) {
                    return _vm.dropFile($event)
                  }
                }
              }),
              _vm.$store.getters["wb/files/isAssetsLoading"]
                ? _c(
                    "div",
                    {
                      staticClass: "secondary--text",
                      attrs: { id: "loaderMessage" }
                    },
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("conference.loadingAssets")))
                      ]),
                      _c("va-loader", {
                        attrs: {
                          isLoading: true,
                          size: "m",
                          color: "secondary"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("context-menu", {
                attrs: { id: "context-menu" },
                on: {
                  makeSnapshot: function($event) {
                    return _vm.$refs.threeDViewer.takeSnapShot()
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }