

























































import Vue from "vue";
import store from "@/store";
import VaLoader from "@/components/atoms/VaLoader.vue";
import ContextMenu from "@/components/organisms/ContextMenu.vue";
import { ShortMeeting } from "@/models/wb/shortMeeting.interface";
import TextBoxDetail from "@/components/organisms/TextBoxDetail.vue";
import * as wbFileService from "@/services/wb/wbFileService";
import VaFrame from "@/components/organisms/VaFrame.vue";
import { BoardElement } from "@/models/wb/boardElement.interface";
import SolidViewer from "@/components/organisms/SolidViewer.vue";

export default Vue.extend({
  data: () => ({
    reset: false,
    background: {
      color: "#FFFFFF",
      image: undefined as string | undefined
    },
    resource: undefined as string | undefined,
    konvaUpdateTick: undefined as undefined | ReturnType<typeof setTimeout>
  }),
  components: {
    SolidViewer,
    VaFrame,
    VaLoader,
    ContextMenu,
    TextBoxDetail
  },
  computed: {
    shortMeeting(): ShortMeeting {
      return store.getters["canvas/shortMeeting"];
    },
    isInitialized(): boolean {
      return store.getters["conference/initialized"];
    },
    focusTextBox(): boolean {
      return store.getters["wb/files/selectedImage"]?.file?.type == "text";
    },
    focusURLBox(): boolean {
      return store.getters["wb/files/selectedImage"]?.file?.type == "url";
    },
    focusVideo(): boolean {
      return store.getters["wb/files/selectedImage"]?.file?.type == "video";
    },
    focusGLB(): boolean {
      return store.getters["wb/files/selectedImage"]?.file?.type == "glb";
    },
    selectedImage() {
      // return store.state.wb.files.selectedImage;
      return store.getters["wb/files/selectedImage"];
    },
    moveActive(): boolean {
      return (
        store.getters["wb/tools/tool"] == "drag" &&
        store.getters["canvas/zoomPercentage"] > 100
      );
    },
    minimized(): boolean {
      return store.getters["videoConference/isMinimized"];
    },
    pinnedCamera(): boolean {
      return store.getters["videoConference/isPinned"];
    },
    mounted(): boolean {
      return store.getters["canvas/mounted"];
    }
  },
  beforeDestroy() {
    store.dispatch("canvas/unMount");
  },
  watch: {
    isInitialized: function(newVal) {
      if (newVal && this.shortMeeting && !this.mounted) {
        this.$nextTick(() => {
          this.mountWhiteboard();
        });
      }
    },
    shortMeeting: function(newVal) {
      if (newVal && this.isInitialized && !this.mounted) {
        this.$nextTick(() => {
          this.mountWhiteboard();
        });
      }
    },
    pageBackground: async function(backgroundData: {
      color: string;
      image: string | undefined;
    }) {
      //lazy fetch of background data
      if (backgroundData.image) {
        this.background.image = await wbFileService.fetchFile(
          backgroundData.image
        );
        this.background.color = "";
        return;
      }
      this.background.image = undefined;
      this.background.color = backgroundData.color;
    },
    selectedImage: async function(selectedImage: BoardElement) {
      let fileType = null;
      if (selectedImage?.file) {
        fileType = selectedImage?.file?.type;
        if (fileType == "video" || fileType == "glb") {
          this.resource = await store.getters["wb/files/cacheFileByUuid"](
            selectedImage.file.fileId
          ).url;
        }
      } else {
        this.resource = undefined;
      }
    },
    minimized: function() {
      this.rescaleKonva();
    },
    pinnedCamera: function() {
      this.rescaleKonva();
    }
  },
  created() {
    window.addEventListener("resize", this.rescaleKonva);
  },
  destroyed() {
    window.removeEventListener("resize", this.rescaleKonva);
  },
  methods: {
    async dropFile(event: DragEvent) {
      if (event?.dataTransfer?.files?.length) {
        const file = event.dataTransfer.files[0];
        const blob = new Blob([file]);
        const url = URL.createObjectURL(blob);
        await wbFileService.uploadFile(url, file.name);
      }
    },
    async mountWhiteboard(): Promise<void> {
      const wrapper = this.$refs["whiteboard-wrapper"] as HTMLElement;
      const availableWidth = wrapper.clientWidth + 2; //reserve space borders
      const availableHeight = wrapper.clientHeight + 2; //reserve space for top&bottom toolbars & border
      await store.dispatch("canvas/mount", {
        containerId: "#wbContainer",
        availableWidth: availableWidth,
        availableHeight: availableHeight
      });
    },
    setDrawToolIfPen(event: PointerEvent): void {
      store.dispatch("wb/tools/setToolByDevice", event);
    },
    rescaleKonva(): void {
      if (this.konvaUpdateTick) clearTimeout(this.konvaUpdateTick);
      //debounce rescale events
      this.konvaUpdateTick = setTimeout(() => {
        const wrapper = this.$refs["whiteboard-wrapper"] as HTMLElement;
        const width = wrapper.clientWidth;
        const height = wrapper.clientHeight;
        store.dispatch("canvas/redraw", { width, height });
        store.commit("wb/files/deselectImage");
      }, 200);
    },
    async createNewImageFromModelViewer(dataUrl: string): Promise<void> {
      await store.dispatch("wb/files/addImageFromModelViewer", dataUrl);
      store.dispatch("wb/files/focusImage");
    }
  }
});
