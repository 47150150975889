



























import Vue from "vue";
import store from "@/store";
import { ModelViewerElement } from "@google/model-viewer/lib/model-viewer";

export default Vue.extend({
  data: () => ({
    modelRotating: false
  }),
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  computed: {
    selectedImage() {
      return store.getters["wb/files/selectedImage"];
    },
    sphericalCoordinates(): string {
      return store.getters["wb/files/selectedImage"]?.file?.details
        ?.sphericalCoordinates;
    },
    modelViewer(): ModelViewerElement {
      return this.$refs.modelViewer as ModelViewerElement;
    }
  },
  watch: {
    sphericalCoordinates: function(newval) {
      if (newval && !this.modelRotating) {
        //coordinates received from socket
        this.modelViewer.cameraOrbit = newval;
      }
    }
  },
  methods: {
    async takeSnapShot(): Promise<void> {
      const url = this.modelViewer.toDataURL("image/png");
      this.$emit("snapshotAcquired", url);
    },
    updateRotation(): void {
      const orbit = this.modelViewer.getCameraOrbit().toString();
      this.selectedImage.file.details = { sphericalCoordinates: orbit };
      this.selectedImage.file.inFullscreen = true;
      store.dispatch("wb/files/updateGlbPosition", this.selectedImage);
    },
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    onZoomModel() {
      this.updateRotation();
    },
    setOrbit() {
      this.modelViewer.cameraOrbit =
        this.selectedImage?.file.details?.sphericalCoordinates || "auto";
    },
    onStartRotateModel() {
      this.modelRotating = true;
    },
    onStopRotateModel() {
      this.modelRotating = false;
      setTimeout(this.updateRotation, 200);
    },
    onRotateModel() {
      if (this.modelRotating) {
        this.updateRotation();
      }
    }
  }
});
