var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({ staticClass: "d-flex" }, on),
                    [
                      _c("va-button", {
                        staticClass: "mx-4",
                        attrs: {
                          icon: "va va-direction-left",
                          color: _vm.iconColor,
                          type: "squareText"
                        },
                        on: {
                          click: function($event) {
                            return _vm.changePage(false)
                          }
                        }
                      }),
                      _c(
                        "va-button",
                        {
                          attrs: {
                            color: _vm.iconColor,
                            type: "squareText",
                            name: "page-tool-btn"
                          },
                          on: { click: _vm.showPageSelector }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pagesIndicator" },
                            [
                              _c("v-icon", [_vm._v("va va-pages")]),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.pageNumber) +
                                    "/" +
                                    _vm._s(_vm.pages.length) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      ),
                      _c("va-button", {
                        staticClass: "mx-4",
                        attrs: {
                          icon: "va va-direction-right",
                          color: _vm.iconColor,
                          type: "squareText"
                        },
                        on: { click: _vm.changePage }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("rooms.handlePages")))])]
      ),
      _vm.pageMenu
        ? _c(
            "div",
            {
              staticClass:
                "d-flex py-4 pl-4 align-center secondary-box border-b",
              attrs: { id: "pages-menu" },
              on: {
                click: function($event) {
                  _vm.pageMenu = false
                }
              }
            },
            [
              _c("va-button", {
                staticClass: "mx-4",
                attrs: { icon: "va va-direction-left", type: "squareText" },
                on: {
                  click: function($event) {
                    return _vm.changePage(false)
                  }
                }
              }),
              _c(
                "div",
                { staticClass: "previews-wrapper d-flex flex-grow-1" },
                _vm._l(_vm.pages, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.uuid,
                      staticClass: "mr-4 cursor-pointer ",
                      class: [
                        item.uuid === _vm.currentPage
                          ? "border-highlight"
                          : "border-a"
                      ],
                      attrs: { index: item.uuid }
                    },
                    [
                      _c("div", {
                        staticClass: "page-preview",
                        attrs: { id: "preview" + item.uuid },
                        on: {
                          click: function($event) {
                            return _vm.gotoPage(item)
                          }
                        }
                      }),
                      _c("div", { staticClass: "page-preview-handler " }, [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c(
                            "div",
                            { staticClass: "mb-2" },
                            [
                              _vm.isWriter
                                ? _c("va-button", {
                                    attrs: {
                                      icon: "va va-trash",
                                      color: _vm.iconColor,
                                      type: "square"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onRemovePage(item.uuid)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _vm.isWriter
                                ? _c("va-button", {
                                    attrs: {
                                      icon: "va va-copy",
                                      color: _vm.iconColor,
                                      type: "square"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onClonePage(item.uuid)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  )
                }),
                0
              ),
              _c("va-button", {
                staticClass: "mr-4",
                attrs: { icon: "va va-direction-right", type: "squareText" },
                on: { click: _vm.changePage }
              }),
              _vm.isWriter
                ? _c("va-button", {
                    staticClass: "mx-4",
                    attrs: { icon: "va va-plus", type: "square" },
                    on: { click: _vm.onAddPage }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }