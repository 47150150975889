import { CanvasFile } from "@/models/wb/canvasFile.interface";
import { wwwImage } from "@/services/wb/elements/placeholders";

function youtube_parser(url: string | undefined): string | false {
  if (!url) return false;
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(live\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[8].length == 11 ? match[8] : false;
}
const placeholderImage = wwwImage;

export const openWindow = (url: string): void => {
  window.open(
    url,
    "html_whiteboard_content",
    "directories=yes,location=no,menubar=yes,resizable=yes,scrollbars=no,status=yes,toolbar=no"
  );
};

export const cleanUrl = (url: string): string => {
  const id = youtube_parser(url);
  if (id) {
    url = `https://www.youtube.com/embed/${id}`;
  }
  return url;
};

export const isVideo = (url: string): boolean => {
  return !!youtube_parser(url);
};

export async function GetWebElementDataUrl(
  file: Partial<CanvasFile>
): Promise<string> {
  if (file.type === "url") {
    const id = youtube_parser(file.details?.text);
    if (id) {
      try {
        // const url = `https://www.googleapis.com/youtube/v3/videos?key=${process.env.VUE_APP_YOUTUBE_API_KEY}&part=snippet&id=${id}`;
        // const data=await res.json()
        // const preview = data.items[0].snippet.thumbnails.default.url;
        // const image = await fetch(preview);

        //HACK for avoiding cors on youtube preview
        const url = `https://api.codetabs.com/v1/proxy/?quest=https://i.ytimg.com/vi/${id}/mqdefault.jpg`;
        // const url = `https://i.ytimg.com/vi/${id}/mqdefault.jpg`;

        const res = await fetch(url);
        const blob = await res.blob();
        return URL.createObjectURL(blob);
      } catch (e) {
        return placeholderImage;
      }
    }
    return placeholderImage;
  } else {
    throw new Error(
      `Unhandled file type in urlPlaceholderDataUrl: ${file.type}`
    );
  }
}
